import theme from '@/style';
import {View} from 'react-native';
import {useInnerStyle} from '../invitation.style.hooks';
import LazyImage from '@/components/basic/image';
import {rightIcon} from '../invitation.variables';
import Icons from '../svg/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import {toPriceStr} from '@/utils';
import {RecordIcon, RulesIcon} from '../svg.variables';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Button from '@/components/basic/button';
import {UserTotal} from '../invitation.type';

export interface InvitationInfoProps {
  onRule?: () => void;
  onRecord?: () => void;
  onAgency?: () => void;
  userTotal?: UserTotal;
  agent: boolean;
  completeCount: number;
}

const InvitationInfo: React.FC<InvitationInfoProps> = ({
  onRule,
  onRecord,
  onAgency,
  agent,
  completeCount,
  userTotal,
}) => {
  const {homeStyle} = useInnerStyle();
  const {t} = useTranslation();
  return (
    <View
      style={[
        theme.flex.col,
        theme.padding.lrxxl,
        theme.padding.tbl,
        homeStyle.bonusCard,
      ]}>
      <View style={[theme.flex.col, theme.padding.btml, homeStyle.infoUpItem]}>
        <View style={[theme.flex.row]}>
          <View
            style={[theme.flex.flex1, theme.flex.col, theme.flex.alignStart]}>
            <View style={[theme.flex.row]}>
              <Icons.InvitationIncome />
              <Text second style={[theme.margin.lefts]}>
                {t('invitation.home.income')}
              </Text>
            </View>
            <div className="text-t1 font-din text-2xl font-bold">
              {toPriceStr(userTotal?.bonusAmountTotal || 0, {
                thousands: true,
                fixed: 0,
              })}
            </div>
          </View>
          <View
            style={[
              theme.flex.flex1,
              homeStyle.infoUpSubItemRight,
              theme.flex.col,
              theme.flex.alignEnd,
            ]}>
            <View style={[theme.flex.row]}>
              <Icons.InvitationPeople />
              <Text second style={[theme.margin.lefts]}>
                {t('invitation.home.count')}
              </Text>
            </View>
            <div className="text-t1 font-din text-2xl font-bold">
              {userTotal?.invitedNumberTotal || 0}
            </div>
          </View>
        </View>
        {!agent && completeCount >= 3 && (
          <View style={[theme.flex.col, theme.margin.topl]}>
            <Button radius={40} type="linear-primary" onPress={onAgency}>
              <View style={[theme.flex.col, theme.flex.center]}>
                <Text
                  color={theme.basicColor.white}
                  blod
                  fontSize={theme.fontSize.m}>
                  {t('invitation.home.join')}
                </Text>
                <Text
                  color={theme.basicColor.white}
                  fontSize={theme.fontSize.xs}>
                  {t('invitation.home.join-tip')}
                </Text>
              </View>
            </Button>
          </View>
        )}
      </View>

      <View style={[theme.flex.row, theme.flex.between, theme.margin.topxxl]}>
        <NativeTouchableOpacity
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={onRecord}>
          <RecordIcon width={theme.iconSize.l} height={theme.iconSize.l} />
          <Text second style={[theme.margin.lrs, homeStyle.infoBottomText]}>
            {t('invitation.home.record')}
          </Text>
          <LazyImage
            imageUrl={rightIcon}
            width={14}
            height={14}
            occupancy="#0000"
          />
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={onRule}>
          <RulesIcon width={theme.iconSize.l} height={theme.iconSize.l} />
          <Text second style={[theme.margin.lrs, homeStyle.infoBottomText]}>
            {t('invitation.home.reward-rules')}
          </Text>
          <LazyImage
            imageUrl={rightIcon}
            width={14}
            height={14}
            occupancy="#0000"
          />
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

export default InvitationInfo;
