import React from 'react';
import DetailNavTitle from '@/components/business/detail-nav-title';
import i18n from '@/i18n';
import InvitationRuleTable from './invitation-rule-table';
import {goBack} from '@/utils';
import InvitationRuleContent from './invitation-rule-content';

const InvitationRule = () => {
  return (
    <div className="flex flex-col overflow-hidden bg-bg w-full h-screen invitation-rule">
      <DetailNavTitle
        hideAmount
        serverRight
        title={i18n.t('invitation.rule.title')}
        onBack={goBack}
      />
      <div className="flex flex-col flex-1 overflow-auto">
        <div className="flex flex-col p-3 gap-3">
          <div className="flex flex-col p-3 gap-3 bg-card text-t1 text-sm">
            <p>{i18n.t('invitation.rule.paragraph.invite')}</p>
            <p>{i18n.t('invitation.rule.paragraph.after')}</p>
          </div>
          <InvitationRuleTable />
          <InvitationRuleContent />
          <div className="h-16 w-full" />
        </div>
      </div>
    </div>
  );
};

export default InvitationRule;
