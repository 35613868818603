import React from 'react';

const InvitationIncome = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M6.57693 5.02554C9.13172 1.92554 12 1.92554 12 1.92554C12 1.92554 15.4875 2.31304 17.4424 5.02554C20.1599 8.32519 22.0641 12.6751 23.3157 16.3386C24.5827 20.0473 21.789 23.6255 18.0059 23.6255H5.98518C2.21151 23.6255 -0.578688 20.0637 0.681957 16.363C1.92765 12.7061 3.83327 8.35471 6.57693 5.02554Z"
      fill="url(#paint0_linear_1658_35152)"
    />
    <g filter="url(#filter0_bi_1658_35152)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0002 8.125C16.7084 8.125 20.5252 6.3901 20.5252 4.25C20.5252 2.1099 16.7084 0.375 12.0002 0.375C7.29197 0.375 3.47519 2.1099 3.47519 4.25C3.47519 6.3901 7.29197 8.125 12.0002 8.125Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0002 8.125C16.7084 8.125 20.5252 6.3901 20.5252 4.25C20.5252 2.1099 16.7084 0.375 12.0002 0.375C7.29197 0.375 3.47519 2.1099 3.47519 4.25C3.47519 6.3901 7.29197 8.125 12.0002 8.125Z"
        stroke="url(#paint1_linear_1658_35152)"
        stroke-opacity="0.2"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M12.5187 10.5V20.9529L10.3343 20.9631V10.5H12.5187ZM14.7798 16.0735H17C17 17.1283 16.8061 18.03 16.4182 18.7786C16.0337 19.5272 15.5011 20.1005 14.8206 20.4986C14.1435 20.8967 13.3609 21.0958 12.4728 21.0958C12.0237 21.0958 11.617 21.0822 11.253 21.055C10.8923 21.0311 10.586 21.0005 10.3343 20.9631L12.4728 19.2992C12.956 19.2992 13.3677 19.1818 13.708 18.947C14.0516 18.7122 14.3153 18.3567 14.4991 17.8803C14.6862 17.4039 14.7798 16.8017 14.7798 16.0735ZM14.1418 11.3319V12.858L9.22159 14.3229V12.8121L14.1418 11.3319ZM14.1418 13.6747V15.1701L9.22159 16.6349V15.1242L14.1418 13.6747Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_bi_1658_35152"
        x="-4.39981"
        y="-7.5"
        width="32.8"
        height="23.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.75" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1658_35152"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1658_35152"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1658_35152"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1658_35152"
        x1="-5.26398"
        y1="26.404"
        x2="16.411"
        y2="28.5445"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FADF4B" />
        <stop offset="1" stop-color="#FF6900" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1658_35152"
        x1="10.6178"
        y1="8.125"
        x2="23.7125"
        y2="-3.98838"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FADF4B" />
        <stop offset="1" stop-color="#FF6900" />
      </linearGradient>
    </defs>
  </svg>
);

const InvitationPeople = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M14.7149 10.4615C17.2859 10.4615 19.3701 8.37728 19.3701 5.8063C19.3701 3.23532 17.2859 1.15112 14.7149 1.15112C12.144 1.15112 10.0598 3.23532 10.0598 5.8063C10.0598 8.37728 12.144 10.4615 14.7149 10.4615Z"
      fill="url(#paint0_linear_1658_35163)"
    />
    <g filter="url(#filter0_bi_1658_35163)">
      <path
        d="M10.0603 11.2371C13.0598 11.2371 15.4914 8.80552 15.4914 5.80604C15.4914 2.80656 13.0598 0.375 10.0603 0.375C7.06086 0.375 4.6293 2.80656 4.6293 5.80604C4.6293 8.80552 7.06086 11.2371 10.0603 11.2371Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M10.0603 11.2371C13.0598 11.2371 15.4914 8.80552 15.4914 5.80604C15.4914 2.80656 13.0598 0.375 10.0603 0.375C7.06086 0.375 4.6293 2.80656 4.6293 5.80604C4.6293 8.80552 7.06086 11.2371 10.0603 11.2371Z"
        stroke="url(#paint1_linear_1658_35163)"
        stroke-opacity="0.2"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M6.18086 22.2103V22.8753H23.2498V22.2103C23.2498 19.7273 23.2498 18.4858 22.7538 17.5374C22.3174 16.7032 21.621 16.025 20.7645 15.5999C19.7909 15.1167 18.5163 15.1167 15.9671 15.1167H13.4636C10.9144 15.1167 9.63983 15.1167 8.66615 15.5999C7.80969 16.025 7.11336 16.7032 6.67697 17.5374C6.18086 18.4858 6.18086 19.7273 6.18086 22.2103Z"
      fill="url(#paint2_linear_1658_35163)"
    />
    <g filter="url(#filter1_bi_1658_35163)">
      <path
        d="M0.749817 22.1435V22.8751H19.3705V22.1435C19.3705 19.4123 19.3705 18.0466 18.8293 17.0034C18.3533 16.0858 17.5936 15.3397 16.6593 14.8721C15.5971 14.3406 14.2066 14.3406 11.4257 14.3406H8.69465C5.91371 14.3406 4.52324 14.3406 3.46104 14.8721C2.52672 15.3397 1.76708 16.0858 1.29103 17.0034C0.749817 18.0466 0.749817 19.4123 0.749817 22.1435Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M0.749817 22.1435V22.8751H19.3705V22.1435C19.3705 19.4123 19.3705 18.0466 18.8293 17.0034C18.3533 16.0858 17.5936 15.3397 16.6593 14.8721C15.5971 14.3406 14.2066 14.3406 11.4257 14.3406H8.69465C5.91371 14.3406 4.52324 14.3406 3.46104 14.8721C2.52672 15.3397 1.76708 16.0858 1.29103 17.0034C0.749817 18.0466 0.749817 19.4123 0.749817 22.1435Z"
        stroke="url(#paint3_linear_1658_35163)"
        stroke-opacity="0.2"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_bi_1658_35163"
        x="-3.2457"
        y="-7.5"
        width="26.6121"
        height="26.6121"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.75" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1658_35163"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1658_35163"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1658_35163"
        />
      </filter>
      <filter
        id="filter1_bi_1658_35163"
        x="-7.12518"
        y="6.46558"
        width="34.3707"
        height="24.2844"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.75" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1658_35163"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1658_35163"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1658_35163"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1658_35163"
        x1="7.80167"
        y1="11.6536"
        x2="16.4921"
        y2="12.4546"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FADF4B" />
        <stop offset="1" stop-color="#FF6900" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1658_35163"
        x1="9.17967"
        y1="11.2371"
        x2="22.3348"
        y2="5.70557"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FADF4B" />
        <stop offset="1" stop-color="#FF6900" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1658_35163"
        x1="2.04101"
        y1="23.8687"
        x2="17.4743"
        y2="26.9983"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FADF4B" />
        <stop offset="1" stop-color="#FF6900" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1658_35163"
        x1="8.55044"
        y1="22.8751"
        x2="22.9608"
        y2="9.65474"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FADF4B" />
        <stop offset="1" stop-color="#FF6900" />
      </linearGradient>
    </defs>
  </svg>
);

export default {
  InvitationIncome,
  InvitationPeople,
};
