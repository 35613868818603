import LinearGradient from '@/components/basic/linear-gradient';
import theme from '@/style';
import {View} from 'react-native';
import {useInnerStyle} from '../invitation.style.hooks';
import {useTranslation} from 'react-i18next';
import {RefreshIcon} from '../svg.variables';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React, {useMemo} from 'react';
import {LazyImageBackground} from '@/components/basic/image';
import {invitationCodeCardImg, invitationColors} from '../invitation.variables';

export interface InvitationCodeProps {
  code?: string;
  onRefreshCode?: () => void;
  onCopy?: () => void;
}

const InvitationCode: React.FC<InvitationCodeProps> = ({
  code,
  onRefreshCode,
  onCopy,
}) => {
  const {
    homeStyle,
    size: {invitationCodeCardImgHeight, invitationCodeCardImgWidth},
  } = useInnerStyle();
  const i18n = useTranslation();

  const renderCode = useMemo(() => {
    const finallyCode = code || '--------';
    return finallyCode.split('').map((c, i) => (
      <Text
        fontSize={theme.fontSize.xl}
        color={'black'}
        blod
        key={i}
        style={[theme.font.center, theme.flex.flex1]}>
        {c}
      </Text>
    ));
  }, [code]);
  return (
    <View style={[theme.flex.col, theme.margin.btml]}>
      <View
        style={[theme.flex.row, homeStyle.codeTitle, theme.flex.centerByCol]}>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          colors={invitationColors.codeLinear}
          style={[
            theme.fill.fillH,
            theme.flex.flex1,
            theme.flex.row,
            theme.flex.centerByCol,
            theme.padding.leftl,
          ]}>
          <Text
            color={invitationColors.codeTitle}
            blod
            fontSize={theme.fontSize.m}>
            {i18n.t('invitation.home.code-title')}
          </Text>
        </LinearGradient>
        <NativeTouchableOpacity
          style={[
            theme.borderRadius.xs,
            homeStyle.resetLink,
            theme.padding.lrs,
            theme.flex.row,
            theme.flex.centerByCol,
          ]}
          onPress={onRefreshCode}>
          <RefreshIcon
            width={theme.iconSize.xs}
            height={theme.iconSize.xs}
            stroke={theme.basicColor.primary}
          />
          <Text color={theme.basicColor.primary} style={theme.margin.leftxxs}>
            {i18n.t('proxy.home.reset-link')}
          </Text>
        </NativeTouchableOpacity>
      </View>
      <LazyImageBackground
        style={[theme.flex.row]}
        imageUrl={invitationCodeCardImg}
        width={invitationCodeCardImgWidth}
        height={invitationCodeCardImgHeight}
        occupancy="#0000">
        <View
          style={[
            homeStyle.codeCardLeft,
            theme.padding.leftl,
            theme.padding.tbxs,
            theme.padding.rights,
          ]}>
          <View
            style={[
              {backgroundColor: 'white'},
              theme.padding.s,
              theme.flex.centerByCol,
              theme.flex.row,
              theme.flex.flex1,
            ]}>
            {renderCode}
          </View>
        </View>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <div
            className="py-1.5 px-6 text-t1 text-sm font-bold rounded-[1.25rem] bg-[var(--1)] flex items-center justify-center h-8"
            onClick={onCopy}>
            {i18n.t('label.copy')}
          </div>
        </View>
      </LazyImageBackground>
    </View>
  );
};

export default InvitationCode;
