import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {defaultTableData} from '../invitation.variables';

import {errorLog, toPriceStr} from '@/utils';
import {UserListItem} from '../invitation.type';
import globalStore from '@/services/global.state';

const InvitationRuleTable = () => {
  const {t} = useTranslation();
  const tableHeader = [
    t('invitation.rule.table.invite'),
    t('invitation.rule.table.deposit'),
    t('invitation.rule.table.bonus'),
  ];
  const [userList, setUserList] = useState<UserListItem[]>();
  useEffect(() => {
    globalStore.asyncGetItem('invitationBonusList').then(user => {
      try {
        user && setUserList(JSON.parse(user) as UserListItem[]);
      } catch (e) {
        errorLog('error', e);
      }
    });
  }, []);

  const tableData = useMemo(() => {
    if (!userList || !userList.length) {
      return defaultTableData.map(([invite, deposit, bonus]) => [
        `${invite} ${t('invitation.rule.table.people')}`,
        toPriceStr(deposit, {fixed: 0, thousands: true}),
        toPriceStr(bonus, {fixed: 0, thousands: true}),
      ]);
    }
    return userList.map(item => [
      `${item.inviteNumber} ${t('invitation.rule.table.people')}`,
      toPriceStr(item.depositAmount, {fixed: 0, thousands: true}),
      toPriceStr(item.bonusAmount, {fixed: 0, thousands: true}),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <div className="flex flex-col rounded-lg overflow-hidden">
      <div className="p-3 flex flex-row items-center justify-between bg-[var(--table-title-color)] text-t1 text-xs">
        <span className="w-[5.625rem] text-left">{tableHeader[0]}</span>
        <span>{tableHeader[1]}</span>
        <span className="w-[5rem] text-right">{tableHeader[2]}</span>
      </div>
      <div className="flex flex-col">
        {/* 此处文字样式不使用变量，直接使用固定值 */}
        {tableData.map((itemCols, index) => (
          <div
            key={index}
            className="p-3 flex flex-row items-center justify-between odd:bg-[var(--table-odd-color)] even:bg-[var(--table-even-color)] text-[#45454D] font-bold text-sm">
            <span className="w-[6rem] text-left">{itemCols[0]}</span>
            <span className="w-[5.625rem] text-center">{itemCols[1]}</span>
            <span className="w-[5rem] text-right">{itemCols[2]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvitationRuleTable;
